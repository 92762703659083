import {generateNoScriptTag} from '../../../helpers/domHelpers';

// google tag manager tracking pixels functions
function gtagReportConversion(url, sendTo) {
  var callback = function() {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  if (!window.gtag) return false;
  window.gtag('event', 'conversion', {
    send_to: sendTo,
    event_callback: callback,
  });
  return false;
}
function sendEvent(sendTo) {
  if (!window.gtag) return;
  window.gtag('event', 'conversion', {
    allow_custom_scripts: true,
    send_to: sendTo,
  });
}
function standardAndUniqueTrackingPixels({standard, unique}) {
  if (!window.gtag) return;
  //Standard tracking pixels by google
  sendEvent(standard.id);
  generateNoScriptTag(standard.src);
  //Unique tracking pixels by google
  sendEvent(unique.id);
  generateNoScriptTag(unique.src);
}

function track(trackingData) {
  const {reportConversion, trackingPixel} = trackingData;
  standardAndUniqueTrackingPixels(trackingPixel);
  if (reportConversion) {
    gtagReportConversion(reportConversion.url, reportConversion.standardId);
    gtagReportConversion(reportConversion.url, reportConversion.uniqueId);
  }
}

export const googleTrackingPixels = (pageType, trackingUrl) => {
  switch (pageType) {
    case 'signup': {
      const data = {
        trackingPixel: {
          standard: {
            id: 'DC-5994466/bleac0/bleac002+standard',
            src:
              'https://ad.doubleclick.net/ddm/activity/src=5994466;type=bleac0;cat=bleac002;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?',
          },
          unique: {
            id: 'DC-5994466/bleac0/bleac003+unique',
            src:
              'https://ad.doubleclick.net/ddm/activity/src=5994466;type=bleac0;cat=bleac003;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?',
          },
        },
        reportConversion: {
          standardId: 'AW-981403438/8bGXCIvl-_0CEK6O_NMD',
          uniqueId: 'AW-981403438/6GBACK-n9_0CEK6O_NMD',
          url: trackingUrl,
        },
      };
      track(data);
    }
    case 'checkout': {
      const data = {
        trackingPixel: {
          standard: {
            id: 'DC-5994466/bleac0/bleac000+standard',
            src:
              'https://ad.doubleclick.net/ddm/activity/src=5994466;type=bleac0;cat=bleac000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?',
          },
          unique: {
            id: 'DC-5994466/bleac0/bleac001+unique',
            src:
              'https://ad.doubleclick.net/ddm/activity/src=5994466;type=bleac0;cat=bleac001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?',
          },
        },
        reportConversion: {
          standardId: 'AW-981403438/-ACRCN2jz_0CEK6O_NMD',
          uniqueId: 'AW-981403438/sGvdCKinz_0CEK6O_NMD',
          url: trackingUrl,
        },
      };
      track(data);
    }
    case 'aew-full-gear-2021-ppv':
    case 'liveEvents': {
      // This is a temporary approach and we are planning for a long-term solution
      const data = {
        trackingPixel: {
          standard: {
            id: 'DC-5994466/bleac0/bleac0+standard',
            src:
              'https://ad.doubleclick.net/ddm/activity/src=5994466;type=bleac0;cat=bleac0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?', //cat={}
          },
          unique: {
            id: 'DC-5994466/bleac0/bleac00+unique',
            src:
              'https://ad.doubleclick.net/ddm/activity/src=5994466;type=bleac0;cat=bleac00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?',
          },
        },
      };
      track(data);
      sendEvent('AW-981403438/S0vYCPidz_0CEK6O_NMD');
      sendEvent('AW-981403438/r75-COTw-_0CEK6O_NMD');
    }
    default:
      break;
  }
};
