import React from 'react';
import classnames from 'classnames';

import ContentStream from '../organisms/contentStream';
import SidebarWidget from '../atoms/sidebarWidget';

class SectionSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onHeadlinesClick = this.onClickFactory('headlines').bind(this);
    this.onTrendingClick = this.onClickFactory('trending').bind(this);
  }
  onClickFactory(key) {
    return (evt, data) => {
      this.props.onClick(key, evt, data);
    };
  }
  render() {
    const classes = classnames({
      cell: true,
      sectionSidebar: true,
      sidebar: true,
      [`widget-${this.props.position}`]: !!this.props.position,
    });

    return (
      <sidebar className={classes}>
        <SidebarWidget key="sidebarWidget" widget={this.props.widget} />
        <ContentStream
          className="headlinesArticles"
          childTag={this.props.childTag}
          header={true}
          imgsize={'box'}
          isTrendingArticle={true}
          key="headlines"
          loadScript={this.props.loadScript}
          onArticleClick={this.onHeadlinesClick}
          page={this.props.page}
          oneTrustPreferences={this.props.oneTrustPreferences}
          scrollPlay={this.props.scrollPlay}
          showTeams={false}
          streamItems={this.props.streams.headlines}
          title="headlines"
          trackEvent={this.props.trackEvent}
          visitorCountry={this.props.visitorCountry}
        />
        <ContentStream
          addScript={this.props.addScript}
          addStyle={this.props.addStyle}
          childTag={this.props.childTag}
          className="fireStream"
          header={true}
          imgsize={'box'}
          isTrendingArticle={true}
          key="fire"
          loadScript={this.props.loadScript}
          numbered={true}
          onArticleClick={this.onTrendingClick}
          page={this.props.page}
          oneTrustPreferences={this.props.oneTrustPreferences}
          scrollPlay={this.props.scrollPlay}
          showTeams={false}
          streamItems={this.props.streams.trending}
          title="Trending"
          trackEvent={this.props.trackEvent}
          visitorCountry={this.props.visitorCountry}
        />
      </sidebar>
    );
  }
}

SectionSidebar.defaultProps = {
  childTag: 'div',
  widget: {},
  streams: [],
  singleStream: true,
  onClick: () => {},
};

export default SectionSidebar;
