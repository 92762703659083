import React from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import * as SectionActions from '../../actions/sectionActions';
import * as StreamActions from '../../actions/streamActions';
import * as AnalyticsActions from '../../actions/analyticsActions';
import * as AppActions from '../../actions/appActions';
import * as Selectors from '../../selectors/sectionSelectors';
import * as TopSelectors from '../../selectors/topLevelSelectors';
import Sidebar from '../cells/sectionSidebar';
import ContentStream from '../organisms/contentStream';
import TeamStreamSelector from '../cells/teamStreamSelector';
import Carousel from '../organisms/carousel';
import {googleTrackingPixels} from '../molecules/video/analytics/gtagEvents';
import {determineReferrerPageType} from '../../actions/helpers/navHelpers/navHelpers';

const calcFeaturedLeadImage = (layout) => {
  return (streamProps, article) => {
    switch (layout) {
      case 'L1L3':
        return streamProps.streamItems.indexOf(article) === 0 ? '50tall' : false;
      case 'L1L5':
        return streamProps.streamItems.indexOf(article) === 0 ? 'default' : false;
      default:
        return false;
    }
  };
};

const calcFeaturedRegularImage = (layout) => {
  switch (layout) {
    case 'L1L3':
      return '50wide';
    case 'L1L5':
      return 'box';
    case 'SIDE':
      return '50tall';
    case 'STACKED':
      return 'default';
    default:
      return 'box';
  }
};

function isFeatured(_props, article) {
  return article.is_featured ||
    article.content_type === 'video' ||
    article.content_type === 'video_article' ||
    article.content_type === 'youtube_video'
    ? 'largeTS'
    : false;
}
const springTypes = {
  main: 'nav_bar',
  sub: 'nav_bar',
  tag: 'stub',
};
export class SectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.onStreamSelect = this.onStreamSelect.bind(this);
    this.onCarouselNext = this.onCarouselNext.bind(this);
    this.onExclusiveArticleClick = this.onExclusiveArticleClick.bind(this);
    this.onTeamStreamArticleClick = this.onTeamStreamArticleClick.bind(this);
    this.determineScreen = this.determineScreen.bind(this);
  }

  componentDidMount() {
    const {user, page, ui, notification} = this.props;
    this.props.onMount({user, page, ui, notification});
    this.trackStreamSelectedEvent();
    if (!window.conversionTracking) {
      window.conversionTracking = this.trackAEWPage.bind(this);
    }

    // Track mParticle Content Viewed event for section pages
    this.props.trackEvent({
      league: this.props.page.league,
      referrer_source: this.props.page.utm_source || global.document.referrer,
      screen: this.determineScreen(),
      streamID: this.props.page.tag_id,
      streamName: this.props.page.id,
      tagsString: this.props.page.tags,
      tag_manager_event: 'content_viewed',
      team: this.props.page.team,
    });
  }
  trackAEWPage = () => {
    //This function will be called in the tagManager side once the gtag script(utag_97) is fully rendered
    googleTrackingPixels(this.props.page.id);
  };
  trackStreamSelectedEvent = () => {
    /*
   to track where the user is coming from 
   navBarTier represents main or sub menu
   springType : 'nav_bar' => the user comes from the menu
   springType :'stub'=> the user comes from the tagLink button in the stub pages 
   springType :'url'=> the user opened the page directly from the browser
   */

    // 'from' query parameter used for tier
    const url = new URL(global.location.href);
    const tier = url.searchParams.get('from');
    const navBarTier = tier ? `${tier} nav` : '';
    url.searchParams.delete('from');
    history.replaceState(null, null, url.href);

    this.props.trackEvent({
      tag_manager_event: 'stream_selected',
      nav_bar_tier: navBarTier,
      spring_type: springTypes[tier] || 'url',
      screen: determineReferrerPageType(this.props.ui?.referrer), //the screen on which the selection takes place
    });
  };
  buildStreamsInfo() {
    if (!this.props.streams) {
      return null;
    }
    return this.props.streams.map((stream) => stream.info);
  }

  onStreamSelect(...args) {
    const limits = {
      tsLimit: this.props.teamStreamLimit,
      trendingLimit: this.props.section.format === 'editorial' ? this.props.trendingLimit : false,
    };
    this.props.onStreamSelect(...args, this.props.currentPage, limits);
  }

  onCarouselNext() {
    this.props.onCarouselNext(this.props.currentPage);
  }

  onExclusiveArticleClick(evt, data) {
    this.props.onArticleClick('evergreen', evt, data);
  }

  onTeamStreamArticleClick(evt, data) {
    this.props.onArticleClick('teamstream', evt, data);
  }

  determineScreen() {
    if (this.props.page.format === 'editorial') {
      return 'Editorial';
    } else if (this.props.page.type === 'live') {
      return 'Live Blog';
    }
    return 'Stream';
  }

  render() {
    const classes = classnames({
      page: true,
      sectionPage: true,
      streamView: !this.props.isEditorial,
      editorialView: this.props.isEditorial,
      [`layout-${this.props.section.layout}`]: true,
    });
    const isSingleStream = this.props.streams && this.props.streams.length === 1;
    const selector = !this.props.singleStream ? (
      <TeamStreamSelector
        selected={this.props.selected}
        tags={this.props.sectionTags}
        onStreamSelect={this.onStreamSelect}
      />
    ) : (
      false
    );

    return (
      <article className={classes}>
        <ContentStream
          addScript={this.props.addScript}
          addStyle={this.props.addStyle}
          childTag="li"
          className="featuredArticles"
          featured={calcFeaturedLeadImage(this.props.section.layout)}
          header={true}
          imgsize={calcFeaturedRegularImage(this.props.section.layout)}
          isFeaturedArticle={true}
          loadScript={this.props.loadScript}
          onArticleClick={this.onTeamStreamArticleClick}
          page={this.props.page}
          oneTrustPreferences={this.props.user && this.props.user.oneTrustPreferences}
          scrollPlay={this.props.ui.scrollPlay}
          showTeams={false}
          streamItems={this.props.featured}
          title="featured"
          trackEvent={this.props.trackEvent}
          visitorCountry={this.props.user && this.props.user.country}
        />
        {this.props.isEditorial ? (
          <Sidebar
            addScript={this.props.addScript}
            addStyle={this.props.addStyle}
            childTag="li"
            loadScript={this.props.loadScript}
            onArticleClick={this.props.onArticleClick}
            page={this.props.page}
            oneTrustPreferences={this.props.user && this.props.user.oneTrustPreferences}
            scrollPlay={this.props.ui.scrollPlay}
            singleStream={this.props.singleStream}
            siteLocale={this.props.user.siteLocale}
            streams={this.props.sidebar}
            tickets={this.props.tickets}
            trackEvent={this.props.trackEvent}
            widget={this.props.widget}
            visitorCountry={this.props.user && this.props.user.country}
          />
        ) : (
          false
        )}
        <div className="teamStreamList">
          <ContentStream
            addScript={this.props.addScript}
            addStyle={this.props.addStyle}
            childTag="li"
            className="selected"
            featured={isFeatured}
            header={true}
            imgsize={'largeTS'}
            info={this.props.teamStream.info}
            isMobileDevice={this.props.ui.isMobileDevice}
            key="teamStreamList"
            loading={this.props.teamStreamLoading}
            loadScript={this.props.loadScript}
            onArticleClick={this.onTeamStreamArticleClick}
            onStreamSelect={this.onStreamSelect}
            page={this.props.page}
            oneTrustPreferences={this.props.user && this.props.user.oneTrustPreferences}
            scrollPlay={this.props.ui.scrollPlay}
            showNewsletterSubsModule={false} // turning off as a result of https://statmilk.atlassian.net/browse/WEB-376
            showtaginfo={!isSingleStream}
            singleStream={this.props.singleStream}
            streamItems={this.props.teamStream.tracks}
            tickets={this.props.tickets}
            title={this.props.ui && this.props.ui.currentPage === 'front-page' ? 'My Teams' : false}
            trackEvent={this.props.trackEvent}
            updateVideosInViewport={this.props.updateVideosInViewport}
            visitorCountry={this.props.user && this.props.user.country}
            upcomingGame={this.props.upcomingGame}
            isTeamSection={this.props.isTeamSection}
          >
            {selector}
          </ContentStream>
        </div>
        <Carousel
          header={true}
          title="Exclusive"
          className="exclusiveArticles"
          articles={this.props.exclusive}
          onCarouselNext={this.onCarouselNext}
          imgsize={'box'}
          onArticleClick={this.onExclusiveArticleClick}
          trackEvent={this.props.trackEvent}
          addScript={this.props.addScript}
          addStyle={this.props.addStyle}
          showTeams={false}
          scrollPlay={this.props.ui.scrollPlay}
          loadScript={this.props.loadScript}
        />
      </article>
    );
  }
}

SectionPage.defaultProps = {
  onMount: () => {}, // stub
  trackEvent: () => {}, // stub
};

/* =========== Below is for Redux State Connecting  =========== */
function mapStateToProps(state) {
  return {
    currentPage: Selectors.selectPageId(state),
    exclusive: Selectors.currentEvergreenTracks(state),
    featured: Selectors.currentFeaturedTracks(state),
    isEditorial: Selectors.selectEditorialState(state),
    notification: TopSelectors.notification(state),
    page: state.page,
    section: Selectors.selectSection(state),
    sectionTags: Selectors.selectPageTags(state),
    sidebar: {
      headlines: Selectors.currentHeadlinesTracks(state),
      trending: Selectors.currentTrendingTracks(state),
    },
    singleStream: Selectors.selectSingleStreamState(state),
    streams: Selectors.selectStreams(state),
    teamStream: Selectors.selectTeamStream(state),
    teamStreamLimit: state.ui.teamStreamLimit,
    teamStreamLoading: state.ui.teamStreamLoading,
    teamStreamSelected: Selectors.selectCurrentTeamStream(state),
    tickets: Selectors.selectTickets(state),
    isTeamSection: Selectors.selectTeamSection(state),
    upcomingGame: Selectors.selectNextUpcomingGame(state),
    trendingLimit: state.ui.trendingLimit,
    ui: TopSelectors.ui(state),
    user: TopSelectors.user(state),
    widget: Selectors.selectWidget(state),
  };
}

// Which action creators does it want to receive by props?
function mapDispatchToProps(dispatch) {
  return {
    addScript: (script) => dispatch(AppActions.addScript(script)),
    addStyle: (script) => dispatch(AppActions.addStyle(script)),
    loadScript: (script) => dispatch(AppActions.loadScript(script)),
    onArticleClick: (payload) => dispatch(AnalyticsActions.article(payload)),
    onCarouselNext: (currentPage) => dispatch(StreamActions.carouselNext(currentPage)),
    onMount: (payload) => {
      return Promise.all([
        dispatch(AppActions.setUiShowData(payload)),
        dispatch(SectionActions.determineFlyin()),
      ]);
    },
    onStreamSelect: (...args) => dispatch(StreamActions.changeStream(...args)),
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
    updateVideosInViewport: (data) => dispatch(AppActions.updateVideosInViewport(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionPage);
