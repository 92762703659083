import React from 'react';
import classnames from 'classnames';
import {SVG} from '@br/br-components';

import ContentStream from './contentStream';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliding: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.getStreamRef = this.getStreamRef.bind(this);
  }
  componentDidMount() {
    if (this._carousel) {
      this._carousel.addEventListener('transitionend', () => this.updateCarousel());
    }
  }
  handleClick() {
    this.setState({sliding: true});
  }
  updateCarousel() {
    this.props.onCarouselNext();
    this.setState({sliding: false});
  }
  getStreamRef(elem) {
    this._carousel = elem;
  }
  render() {
    const classes = classnames(this.props.className, {
      carousel: true,
      organism: true,
      sliding: this.state.sliding,
    });
    return (
      <ContentStream
        {...this.props}
        childTag="li"
        className={classes}
        header={true}
        onArticleClick={this.props.onArticleClick}
        page={this.props.page}
        oneTrustPreferences={this.props.oneTrustPreferences}
        scrollPlay={this.props.scrollPlay}
        streamItems={this.props.articles}
        streamRef={this.getStreamRef}
        trackEvent={this.props.trackEvent}
      >
        <div className="carouselNext" onClick={this.handleClick}>
          {' '}
          <SVG type="icon" target="rightArrow" />
        </div>
      </ContentStream>
    );
  }
}

Carousel.defaultProps = {
  title: '',
  articles: [],
  info: {},
  noimages: false,
  allowliveblog: false,
  showarrow: false,
  onArticleClick() {},
};

export default Carousel;
