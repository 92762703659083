import React from 'react';
import Iframe from './iframe';

const SidebarWidget = (props) => {
  if (!props.widget) {
    return false;
  }

  return (
    <div className="atom sidebarWidget">
      <Iframe src={props.widget.code} />
    </div>
  );
};

export default SidebarWidget;
