import React from 'react';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import {Button, SVG} from '@br/br-components';

import TagLink from '../atoms/tagLink';

class TeamStreamSelector extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onToggleDropdown = this.onToggleDropdown.bind(this);
    this.debouncedOnResize = debounce(this.onResize.bind(this), 200);
    this.state = {
      collapsed: true,
      isDropdownVisible: false,
    };
  }
  componentDidMount() {
    global.addEventListener('resize', this.debouncedOnResize);
    this.onResize();
  }
  onClick(...args) {
    return this.props.onStreamSelect(...args);
  }
  buildTagList() {
    if (!this.props.tags) {
      return null;
    }
    let tagList = this.props.tags;
    if (tagList.length > 1) {
      tagList = [
        {
          short_name: 'All Streams',
          permalink: 'all',
        },
        ...this.props.tags,
      ];
    }
    return tagList.map((tag) => {
      return (
        <TagLink
          key={tag.permalink || tag.unique_name}
          info={tag}
          teamColor={tag.team_color}
          useShortName={true}
          selected={tag.permalink === this.props.selected}
          onClick={this.onClick}
        />
      );
    });
  }
  onToggleDropdown() {
    this.setState({collapsed: !this.state.collapsed});
  }
  onResize() {
    this.setState({
      isDropdownVisible: this.tagLinkWrapper.clientHeight > this.teamStreamSelector.clientHeight,
    });
  }
  render() {
    const classes = classnames({
      cell: true,
      collapsed: this.state.collapsed,
      teamStreamSelector: true,
      isDropdownVisible: this.state.isDropdownVisible,
    });
    const streamList = this.buildTagList();

    return (
      <div
        className={classes}
        ref={(elem) => {
          this.teamStreamSelector = elem;
        }}
      >
        <div
          className="tagLinkWrapper"
          ref={(elem) => {
            this.tagLinkWrapper = elem;
          }}
        >
          {streamList}
        </div>
        <Button onClick={this.onToggleDropdown}>
          <SVG type="icon" target="upArrow" />
        </Button>
      </div>
    );
  }
}

TeamStreamSelector.defaultProps = {
  streams: [],
  onStreamSelect() {}, // stub
};

export default TeamStreamSelector;
