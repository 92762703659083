import {createAction} from 'redux-actions';
import LayserBeamAPI from '../apis/layser_api';
import * as Selectors from '../selectors/sectionSelectors';

const append_ts = (tag) => {
  return `${tag}_ts`;
};

export const fetchStreamData = createAction(
  'FETCH_STREAM_DATA',
  async (tags, limit, streamName) => {
    let tagList = tags;
    let section = tags;
    if (Array.isArray(tags) && tags.length > 1) {
      tagList = tags.map(append_ts);
      section = 'all';
    }
    const data = await LayserBeamAPI.stream(tagList, limit);
    return {
      ...data,
      section,
      streamName,
    };
  }
);

export const incrementLimit = createAction('INCREMENT_LIMITS', async (limits) => limits);

export const resetLimit = createAction('RESET_LIMITS', async (limits) => limits);

const updateSelectedStream = createAction('UPDATE_SELECTED_STREAM', (section, streamName) => {
  return {
    section,
    streamName,
  };
});

export const loadStreamData = (tag, section, limits, increment) => {
  const updateLimit = increment ? incrementLimit : resetLimit;

  return (dispatch, getState) => {
    let tags = [];
    return dispatch(updateLimit(limits)).then(() => {
      const state = getState();
      const limits = Selectors.selectStreamLimits(state);
      if (tag === 'all') {
        // We've got to update the blended streams, so lets get those.
        tags = Selectors.selectPageTagIds(state);
        return dispatch(fetchStreamData(tags, limits.tsLimit, section));
      }
      if (increment) {
        tags = tag;
        if (tag === 'front-page' || tag === 'uk') {
          tags = Selectors.selectPageTagIds(state);
        }
        const trendingTag = Selectors.selectSection(state).trending;
        return Promise.all([
          dispatch(fetchStreamData(tags, limits.tsLimit, section)),
          dispatch(fetchStreamData(trendingTag, limits.trendingLimit, section)),
        ]);
      }
      return dispatch(fetchStreamData(tag, limits.tsLimit, section));
    });
  };
};

export const changeStream = (tag, section, limits, increment) => {
  return (dispatch) => {
    return dispatch(loadStreamData(tag, section, limits, increment)).then(() => {
      dispatch(updateSelectedStream(tag, section));
    });
  };
};

export const shiftPlaylist = createAction('SHIFT_PLAYLIST');

export const carouselNext = async () => {
  return (dispatch, getState) => {
    const section = Selectors.selectSection(getState());
    return dispatch(shiftPlaylist(section.evergreen));
  };
};
