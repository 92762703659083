import Boom from 'boom';
import {createAction} from 'redux-actions';
import {format, subHours} from 'date-fns';

import logger from '../logger';
import reporter from '../reporter';

import {addScript, setTitle} from './appActions';
import {createFlyin} from './promoActions';

import BreportAPI from '../apis/breport_api';
import FaustAPI from '../apis/faust_api';
import StatMilkAPI from '../apis/statmilk/schedules_api';
import LayserAPI from '../apis/layser_api';
import StorageAPI from '../apis/storage_api';
import ImagesAPI from '../apis/images_api';

import {isGameStream} from '../helpers/streamHelpers';
import {homePageTags} from '../constants/tags';
import {BR} from '../endpoints';

const BR_SocialPromos_campaigns = {
  facebook: 'facebook',
  twitter: 'twitter',
  send2phone: 'send2phone',
};

const REGEX_FACEBOOK = /:\/\/[^/]*facebook\.com\//;
const REGEX_TWITTER = /:\/\/[^/]*twitter\.com\//;
const REGEX_T_DOT_CO = /:\/\/[^/]*t\.co\//;
const REGEX_NON_NUMERIC_CHAR = /\D/;

const LIVESTREAM_REFRESH_INTERVAL_MSEC = 30000;

function getReferrer() {
  const referrer = global.document.referrer;
  if (REGEX_TWITTER.test(referrer) || REGEX_T_DOT_CO.test(referrer)) {
    return BR_SocialPromos_campaigns.twitter;
  }
  if (REGEX_FACEBOOK.test(referrer)) {
    return BR_SocialPromos_campaigns.facebook;
  }
  return '';
}

function determineSnoozeStorageKey(campaign) {
  switch (campaign) {
    case 'facebook':
      return 'facebook_liked_accounts';
    case 'twitter':
      return 'twitter_followed_accounts';
    case 'send2phone':
      return 'send2phone_kill';
    default:
      logger.warn(`determineSnoozeStorageKey saw unrecognized campaign: ${campaign}`);
      return null;
  }
}

function extractCampaignClosedAccounts(campaign) {
  const nowInMilliseconds = new Date().getTime();
  const campaignSnoozeData = StorageAPI.get(
    determineSnoozeStorageKey(BR_SocialPromos_campaigns[campaign])
  );
  if (!campaignSnoozeData) {
    return null;
  }
  const accountsSnoozed = Object.keys(campaignSnoozeData).reduce(function(accounts, socialAccount) {
    const campaignData = campaignSnoozeData[socialAccount];
    if (campaignData.expires > nowInMilliseconds) {
      accounts.push(socialAccount);
    }
    return accounts;
  }, []);
  if (accountsSnoozed.length) {
    return accountsSnoozed.join(',');
  }
  return null;
}

const fetchAndStoreParams = createAction('FETCH_AND_STORE_PARAMS', () => {
  return {
    facebook: extractCampaignClosedAccounts(BR_SocialPromos_campaigns.facebook),
    referrer: getReferrer(),
    teamstream: extractCampaignClosedAccounts(BR_SocialPromos_campaigns.send2phone),
    twitter: extractCampaignClosedAccounts(BR_SocialPromos_campaigns.twitter),
  };
});

function isPageTypeToShowFlyin(page) {
  return page.type === 'section' && !homePageTags.all.includes(page.id);
}

export const determineFlyin = () => (dispatch, getState) => {
  const {page, ui} = getState();
  const campaign = ui.show_android_ts_promo ? 'androidTsPromo' : '';
  const promises = [dispatch(fetchAndStoreParams())];
  if (isPageTypeToShowFlyin(page)) {
    promises.push(dispatch(createFlyin(campaign)));
  }
  return Promise.all(promises);
};

const loadPlaylistMetadata = createAction('LOAD_PLAYLIST_METADATA', async (playlistId) => {
  const faustData = await FaustAPI.getPlaylistMetadata(playlistId);
  if (!faustData.permalink) {
    throw Boom.notFound("That doesn't seem to exist.");
  }
  const url = await BreportAPI.liveblogUrl(faustData.permalink);

  return {
    ...faustData,
    url,
  };
});

function teamJsonFor(team) {
  return {
    '@type': 'SportsTeam',
    name: team.name,
    sport: team.sport,
    logo: team.logo,
    memberOf: team.member_of,
    alternateName: team.alt_name,
    image: team.image,
    url: `https://bleacherreport.com/${team.permalink}`,
  };
}

function liveWhitelist(track) {
  return (
    (track.content_type === 'video' ||
      track.content_type === 'text' ||
      track.content_type === 'photo' ||
      track.content_type === 'tweet' ||
      track.content_type === 'youtube_video' ||
      track.content_type === 'facebook_image_post' ||
      track.content_type === 'facebook_post' ||
      track.content_type === 'facebook_video_post' ||
      track.content_type === 'instagram_video' ||
      track.content_type === 'instagram_image') &&
    track.content.commentary &&
    ((track.content.commentary.title && track.content.commentary.title.length) ||
      (track.content.commentary.description && track.content.commentary.description.length))
  );
}

const REGEX_H_W_PARAM_VALUES_IN_URL = /[?&]h=(\d+).*w=(\d+)/;

function extractWidthAndHeight(url) {
  const matches = REGEX_H_W_PARAM_VALUES_IN_URL.exec(url);
  if (matches && matches[2]) {
    return {
      width: matches[2],
      height: matches[1],
    };
  }
  return {};
}

function imageObjectJsonFromUrl(imageUrl) {
  const {width, height} = extractWidthAndHeight(imageUrl);
  if (width && height) {
    return {
      '@type': 'ImageObject',
      url: imageUrl,
      width,
      height,
    };
  }
  return {
    '@type': 'ImageObject',
    url: imageUrl,
  };
}

// image referenced here should always be max height 60 and max height 600
const publisherSchemaInfo = {
  '@type': 'Organization',
  name: 'Bleacher Report',
  url: 'https://bleacherreport.com/',
  logo: {
    '@type': 'ImageObject',
    url: ImagesAPI.brLogo('height60'),
    width: 80,
    height: 60,
  },
};

function makeTracksJson(layserData, about) {
  if (!layserData || !layserData.tracks) {
    return false;
  }

  return layserData.tracks.filter(liveWhitelist).map((track) => {
    const schema = {
      headline: (track.content.commentary && track.content.commentary.title) || track.content.title,
      '@type': 'BlogPosting',
      url: `https://bleacherreport.com/${track.tag.unique_name}`,
      datePublished: track.created_at,
      dateModified: track.updated_at,
      author: about.author_name,
      publisher: publisherSchemaInfo,
    };

    if (track.content.commentary) {
      schema.articleBody = track.content.commentary.description;
    }

    if (track.content_type === 'video') {
      schema.video = {
        '@type': 'VideoObject',
        thumbnail: track.content.thumbnail_url,
      };
    } else {
      schema.image = imageObjectJsonFromUrl(track.content.thumbnail_url || about.stream_photo);
    }

    return schema;
  });
}

function makeLdJson({key, content}) {
  return {
    key,
    type: 'application/ld+json',
    content: JSON.stringify(content),
  };
}

function findMostRecentlyUpdatedTrack(mostRecentTrack, track) {
  if (
    !mostRecentTrack ||
    !mostRecentTrack.updated_at ||
    track.updated_at > mostRecentTrack.updated_at
  ) {
    return track;
  }
  return mostRecentTrack;
}

function makeAmpJson(
  {about, headline, alt_headline, description, keywords, permalink},
  layserData
) {
  const commonContent = {
    '@context': 'http://schema.org',
    '@type': 'LiveBlogPosting',
    alternativeHeadline: alt_headline,
    articleBody: description,
    author: {
      '@type': 'Person',
      name: about.author_name,
      '@id': about.author_profile_url,
    },
    description,
    headline,
    image: about.stream_photo,
    inLanguage: 'English',
    keywords,
    name: headline,
    publisher: publisherSchemaInfo,
    url: `https://bleacherreport.com/${permalink}`,
  };

  if (layserData && layserData.tracks && layserData.tracks.length > 0) {
    commonContent.dateModified = layserData.tracks.reduce(findMostRecentlyUpdatedTrack).updated_at;
  }

  if (about) {
    const aboutContent = {
      '@type': 'SportsEvent',
      name: headline,
    };
    if (about.start_date) {
      aboutContent.startDate = about.start_date;
      commonContent.coverageStartTime = format(subHours(about.start_date, 2));
    }
    if (about.author_name) {
      commonContent.liveBlogUpdate = makeTracksJson(layserData, about);
    }
    if (about.location) {
      aboutContent.location = {
        '@type': 'PostalAddress',
        name: about.location,
      };
    }
    if (about.away_team && about.home_team) {
      aboutContent.homeTeam = teamJsonFor(about.home_team);
      aboutContent.awayTeam = teamJsonFor(about.away_team);
    }
    commonContent.about = aboutContent;
  }
  return makeLdJson({key: 'seo-amp-eventdata', content: commonContent});
}

const loadSectionDataAction = createAction('LOAD_SECTION_DATA');

const setLiveImage = createAction('SET_LIVE_IMAGE');

const setLiveMetaData = (faustData, layserData) => async (dispatch) => {
  if (isGameStream(faustData.type) || faustData.type === 'breaking') {
    return Promise.all([
      dispatch(
        addScript({key: 'seo-amp-core', src: 'https://cdn.ampproject.org/v0.js', async: true})
      ),
      dispatch(
        addScript({
          key: 'seo-amp-share',
          src: 'https://cdn.ampproject.org/v0/amp-social-share-0.1.js',
          async: true,
          'custom-element': 'amp-live-list',
        })
      ),
      dispatch(addScript(makeAmpJson(faustData, layserData))),
      dispatch(setTitle({title: faustData.headline})),
      dispatch(setLiveImage(faustData.about.stream_photo)),
    ]);
  }
  return Promise.resolve();
};

function addPropertyAliasesToFaustTag(faustTag) {
  return {
    ...faustTag,
    id: faustTag.tag_id,
    logo_filename: faustTag.logo,
    name: faustTag.display_name,
    permalink: faustTag.unique_name,
  };
}

const setBreadcrumbSchema = ({section, tagList = [], isGamecast = false}) => async (dispatch) => {
  const currentTag = tagList.find((tag) => tag.unique_name === section);
  const title = currentTag ? currentTag.display_name : section;

  const breadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': BR.baseURL(),
          name: 'Home',
        },
      },
    ],
  };

  //Since homepage is recognized as a section, only show extra breadcrumb if NOT homepage
  if (section && !homePageTags.us.includes(section)) {
    breadcrumbSchema.itemListElement.push({
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': isGamecast ? BR.gamecast(section) : BR.section(section),
        name: title,
      },
    });
  }

  return dispatch(
    addScript({
      key: 'BreadcrumbSchema',
      type: 'application/ld+json',
      content: JSON.stringify(breadcrumbSchema),
    })
  );
};

// Set competitors of a game to an array
const generateCompetitor = (teams) => {
  const competitor = [];
  for (const key in teams) {
    if (teams.hasOwnProperty(key) && teams[key]?.name) {
      competitor.push({
        '@type': 'SportsTeam',
        name: teams[key]?.name,
      });
    }
  }
  return competitor;
};

// Set LD+JSON Sport Event
const setSportsEvent = async (section) => async (dispatch) => {
  const {live_game} = await LayserAPI.getGamecast(section);
  const sportEvent = {
    '@context': 'https://schema.org',
    '@type': 'SportsEvent',
    name: live_game?.analytics?.headline_title,
    competitor: generateCompetitor(live_game?.player_stats),
    startDate: live_game?.analytics?.game_start,
    location: {
      '@type': 'City',
      name: live_game?.sharing?.location?.city,
    },
  };

  return dispatch(addScript(makeLdJson({key: 'seo-amp-eventdata', content: sportEvent})));
};

export const loadSectionData = ({
  section,
  tags: tagSlugsOrIds = [],
  isLive = false,
  visitorCountryCode,
  isGamecast = false,
}) => async (dispatch) => {
  let layserData, faustTagsData;
  if (tagSlugsOrIds.length && REGEX_NON_NUMERIC_CHAR.test(tagSlugsOrIds[0])) {
    [layserData, faustTagsData] = await Promise.all([
      LayserAPI.section(section, tagSlugsOrIds, {visitorCountryCode}),
      FaustAPI.getTagInfo(tagSlugsOrIds.join(',')),
    ]);
  } else {
    // tagSlugsOrIds is array of numeric IDs (...or empty)
    faustTagsData = await FaustAPI.getTagInfo(tagSlugsOrIds.join(','));
    const tagSlugs = faustTagsData.map((tagData) => tagData.unique_name);
    layserData = await LayserAPI.section(section, tagSlugs, {visitorCountryCode});
  }

  if (!layserData || layserData.error) {
    throw layserData.error || Boom.notFound(`Problem loading section ${section}`);
  }

  //This condition will result in no content to display on section page. Return 404 instead
  const noSections = !layserData.sections || layserData.sections.length === 0;
  const noTracks = !layserData.tracks || layserData.tracks.length === 0;
  const noModules = !layserData.modules || layserData.modules.length === 0;
  const notGamecast = layserData.type !== 'game_stream';

  if (noSections && noTracks && noModules && notGamecast) {
    throw Boom.create(404, `No content for section: ${section}`);
  }

  const layserTags = layserData && layserData.tags ? layserData.tags : [];
  let tagList =
    faustTagsData.error || !Array.isArray(faustTagsData)
      ? []
      : [...faustTagsData.map(addPropertyAliasesToFaustTag)];
  tagList = layserTags.length ? [...layserTags, ...tagList] : tagList;

  const promises = [
    dispatch(
      loadSectionDataAction({
        ...layserData,
        tags: tagList,
        section,
      })
    ),
    dispatch(setBreadcrumbSchema({section, tagList, isGamecast})),
  ];

  // Set SportsEvent schema on Web Gamecast Pages
  if (isGamecast) {
    promises.push(dispatch(setSportsEvent(section)));
  }

  if (!homePageTags.us.includes(section)) {
    const currentTag = tagList.find((tag) => tag.unique_name === section);
    const title = currentTag ? currentTag.display_name : '';
    if (isLive) {
      if (layserData.playlistId) {
        promises.push(
          dispatch(loadPlaylistMetadata(layserData.playlistId)).then((response) =>
            dispatch(setLiveMetaData(response.payload, layserData))
          )
        );
      } else {
        reporter.inform('No Playlist ID for Live Event', {
          section,
          layserData,
        });
      }
    } else {
      const sectionType = currentTag ? currentTag.type : '';
      const league = layserData.page.league;
      const altTags = layserData.page.tags;
      promises.push(dispatch(setTitle({title, sectionType, league, altTags})));
    }
  }

  return Promise.all(promises);
};

export const updateLivestream = createAction('UPDATE_LIVESTREAM', async (sectionSlug) => {
  return {
    ...(await LayserAPI.section(sectionSlug, [])),
    sectionSlug,
  };
});

let timeoutId;

const setLivestreamRefresh = (slug) => async (dispatch) => {
  if (!timeoutId) {
    /* eslint-disable no-use-before-define */
    timeoutId = global.setTimeout(
      () => dispatch(updateLivestreamAndCatch(slug)),
      LIVESTREAM_REFRESH_INTERVAL_MSEC
    );
    /* eslint-enable no-use-before-define */
  }
  return timeoutId;
};

export const clearLivestreamRefresh = createAction('CLEAR_LIVESTREAM_REFRESH', async () => {
  if (timeoutId) {
    global.clearTimeout(timeoutId);
    timeoutId = null;
  }
  return true;
});

let fetchInProgress = false;

export const updateLivestreamAndCatch = (sectionSlug) => async (dispatch) => {
  if (fetchInProgress) {
    return false;
  }
  fetchInProgress = true;
  return dispatch(clearLivestreamRefresh())
    .then(() => dispatch(updateLivestream(sectionSlug)))
    .then(() => {
      fetchInProgress = false;
      dispatch(setLivestreamRefresh(sectionSlug));
    })
    .catch((err) => {
      fetchInProgress = false;
      logger.error('error updating livestream', err);
      dispatch(clearLivestreamRefresh());
      throw err;
    });
};

export const loadGames = createAction('LOAD_TEAM_GAMES', async (team) => {
  const scheduleData = await StatMilkAPI.fetchGamesByTeam(team);

  return {
    schedule: scheduleData?.schedule_groupings ? scheduleData : {},
    team,
  };
});
