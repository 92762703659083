import {BR, Cloudinary} from '../../../endpoints';
import {ARTICLE, GAMECAST, STUB, VIDEO} from '../../../constants/pageTypes';
import {DropdownType} from '../../../components_v2/NavBar/types';
import {LOGO_SIZE} from '../../../constants/logos';

const addTrackEvent = (title) => {
  if (title === 'AEW PPV') {
    return 'stream_selected';
  }
  if (title === 'SCORES') {
    return 'scores_selected';
  }
  return null;
};

const formatLink = (tier) => {
  return ({title, href, children}) => {
    return {
      title,
      // add the navigation 'tier' to our href for tracking in section pages
      href: href && `${href}?from=${tier}`,
      eventName: addTrackEvent(title),
      children: children?.length && {
        type: DropdownType.Default,
        links: children.map(formatLink(tier)),
      },
    };
  };
};

const verifyLink = (link) => {
  return link.href || link.children?.links?.length || link.children?.sections?.length;
};

const generateTeamLinks = (tag) => {
  const links = [];

  if (tag.ticketsLink && process.env.ENABLE_TICKETS_LINKS) {
    links.push({
      title: 'Tickets',
      href: tag.ticketsLink,
      external: true,
      eventName: 'ticket_link_selected',
    });
  }

  links.push({
    title: 'Schedule',
    href: `/${tag.permalink}/schedule`,
  });

  return links;
};

const createTeamLink = (team) => ({
  title: team.name,
  href: `/${team.permalink}?from=sub`,
  icon: team.logo && `${Cloudinary.teamLogo(LOGO_SIZE.Small)}/${team.logo}`,
});

const generateSections = (teams) => {
  const sections = teams.reduce((accum, team) => {
    for (const parent of team.parents) {
      // if a team belongs to either of these Leagues, we want to list them under those headers, as well
      if (['UEFA Champions League', 'UEFA Europa League'].includes(parent.name)) {
        accum[parent.name] ||= {name: parent.name, position: parent.position, teams: []};
        accum[parent.name].teams.push(createTeamLink(team));
      }
    }

    accum[team.parent.name] ||= {name: team.parent.name, position: team.parent.position, teams: []};
    accum[team.parent.name].teams.push(createTeamLink(team));

    return accum;
  }, {});

  // order our teams alphabetically
  for (const section in sections) {
    if (sections.hasOwnProperty(section)) {
      sections[section].teams.sort((aTeam, bTeam) => {
        return aTeam.title < bTeam.title ? -1 : 1;
      });
    }
  }

  // remove any leagues that have fewer than 5 teams
  // order our leagues/divisions based on the tag's priority
  return Object.values(sections)
    .filter((section) => section.teams.length >= 4)
    .sort((aSection, bSection) => {
      if (aSection.position === null) {
        return -1;
      }

      if (bSection.position === null) {
        return 1;
      }

      return aSection.position < bSection.position ? -1 : 1;
    });
};

export const generateNavigation = (menuData, tag) => {
  const {teams, ...menu} = menuData;
  menu.mainNavigation.links = menu.mainNavigation.links.map(formatLink('main')).filter(verifyLink);

  if (!menu.subNavigation) {
    return menu;
  }

  menu.subNavigation.links = menu.subNavigation.links.map(formatLink('sub'));
  menu.subNavigation.href = `/${tag.root?.permalink || tag.permalink}`;

  const teamsIndex = menu.subNavigation.links.findIndex((link) => {
    return link.title.toLowerCase() === 'teams';
  });

  if (teamsIndex >= 0 && teams?.length) {
    // if we have teams, inject them into the subnav
    menu.subNavigation.links[teamsIndex].children = {
      type: DropdownType[teams[0].parent?.type] || DropdownType.Division,
      sections: generateSections(teams),
    };
  } else if (teamsIndex >= 0) {
    // otherwise, remove the entry
    menu.subNavigation.links.splice(teamsIndex, 1);
  }

  if (tag?.type === 'Team') {
    // if we're viewing a team, remove all links after 'teams' and add 'tickets' and 'schedule' links
    menu.subNavigation.links.splice(
      teamsIndex + 1,
      menu.subNavigation.links.length - teamsIndex,
      ...generateTeamLinks(tag)
    );
  }

  menu.subNavigation.links = menu.subNavigation.links.filter(verifyLink);

  return menu;
};

export const determineReferrerPageType = (referrerUrl) => {
  const hostName = BR.host();
  const regexPattern = new RegExp(`${hostName.replace('.com', '\\.com')}\\/([\\w-]*)(\\/?)`);
  const results = referrerUrl?.match(regexPattern);
  const pageType = results && results.length > 1 && results[1];

  switch (pageType) {
    case 'articles':
      return ARTICLE;
    case 'game':
      return GAMECAST;
    case 'post':
      return STUB;
    case '':
      return 'home';
    case 'videos':
      return VIDEO;
    default:
      return '';
  }
};

export const defaultNavigation = {
  fallback: true,
  mainNavigation: {
    links: [
      {
        title: 'SPORTS',
        href: null,
        children: {
          type: 'default',
          links: [
            {
              title: 'WORLD FOOTBALL',
              href: '/world-football?from=main',
            },
            {
              title: 'NFL',
              href: '/nfl?from=main',
            },
            {
              title: 'NBA',
              href: '/nba?from=main',
            },
            {
              title: 'WWE',
              href: '/wwe?from=main',
            },
            {
              title: 'AEW',
              href: '/all-elite-wrestling?from=main',
            },
            {
              title: 'MMA',
              href: '/mma?from=main',
            },
            {
              title: 'BOXING',
              href: '/boxing?from=main',
            },
            {
              title: 'NASCAR',
              href: '/nascar?from=main',
            },
            {
              title: 'F1',
              href: '/formula-1?from=main',
            },
            {
              title: 'MLB',
              href: '/mlb?from=main',
            },
            {
              title: 'GOLF',
              href: '/golf?from=main',
            },
            {
              title: 'RUGBY',
              href: '/rugby?from=main',
            },
            {
              title: 'NHL',
              href: '/nhl?from=main',
            },
            {
              title: 'TENNIS',
              href: '/tennis?from=main',
            },
            {
              title: 'CRICKET',
              href: '/cricket?from=main',
            },
            {
              title: 'CFB',
              href: '/college-football?from=main',
            },
            {
              title: 'WNBA',
              href: '/wnba?from=main',
            },
          ],
        },
      },
      {
        title: 'WATCH',
        href: null,
        children: {
          type: 'default',
          links: [
            {
              title: 'VIDEOS',
              href: '/videos?from=main',
            },
            {
              title: 'AEW PPV',
              href: '/all-elite-wrestling-ppv?from=main',
              eventName: 'stream_selected',
            },
          ],
        },
      },
      {
        title: 'CULTURE',
        href: null,
        children: {
          type: 'default',
          links: [
            {
              title: 'TRENDING',
              href: '/trending?from=main',
            },
            {
              title: 'B/R BETTING',
              href: '/sports-odds?from=main',
            },
            {
              title: 'B/R KICKS',
              href: '/kicks?from=main',
            },
            {
              title: 'B/R GAMING',
              href: '/gaming?from=main',
            },
            {
              title: 'H/H',
              href: '/house-of-highlights?from=main',
            },
            {
              title: 'HighlightHER',
              href: '/highlighther?from=main',
            },
            {
              title: 'B/R SHOP',
              href: 'https://www.bleacherreportshop.com?from=main',
            },
          ],
        },
      },
    ],
  },
  subNavigation: null,
};
